import {CustomerData} from "../customer/CustomerModel";
import app from "../../main";

export class CallRoom {
    selectedPhone = ""
    /**
     * Сообщения в карточке клиента. Отображается рядом с иконкой поиска
     * @type {string}
     */
    searchMessage = ""
    /**
     * Тип сообщения
     * @type {'success'|'error'|'loading'|''}
     */
    searchMessageType = ""

    // О клиенте
    savedUser = null
    customerLoading = false
    customerFetching = false
    /**
     * Клиенты банка
     * @type {CustomerData[]}
     */
    customers = []
    /**
     * Форма в карточке клиента
     * @type {CustomerData}
     */
    customerForm = {}
    /**
     * Поля, которые нужно подсветить
     * @type {string[]}
     */
    customerFieldsUpdated = []
    isNewClient = true
    searchFailed =  false

    lang = ""

    /**
     * id выбранного клиента
     * @type {null|number|string}
     */
    selectedCustomer = null

    // Обращения
    customersProblems = {}
    problemsActions = {}
    isNewProblem = true
    selectedProblem = null

    // В data хранятся только фиксированные данные, которые не будут меняться
    data = {
        source: "",
        sourceId: null,
        phone: "",
        lang: "uz",
        task_id: null
    }

    constructor(callData) {
        let clientSource;
        if(callData) {
            clientSource = app.$store.state.incomingClientSources.find(source => source.queue.includes(callData.called_number));
        }
        if (callData.customer_type === "ISHONCH") {
            clientSource = app.$store.state.incomingClientSources.find(source => source.slug === "tel-doveriya");
        }
        if(!clientSource) {
            clientSource = app.$store.state.incomingClientSources.find(source => source.slug === "call-center");
        }
        if(clientSource) {
            this.data.sourceId = clientSource.id;
            this.data.source = clientSource.name;
        }

        this.lang = this.data.lang = callData.language;
        this.data.phone = callData.phone;
        this.data.task_id = callData.task_id;

        this.customerForm = new CustomerData(null, callData.phone);
    }
}

export class ChatRoom extends CallRoom {
    // Сообщения
    /**
     * Скрол в списке сообщений
     * @type {number}
     */
    savedScroll = 0
    /**
     * Пагинация для старых сообщений
     * @type {number}
     */
    messagesPage = 0
    oldMessages = []
    /**
     * Показывает, что мы пришли к самому первому сообщению
     * @type {boolean}
     */
    stopFetchingOldMessages = false
    messages = []
    unreadMessages = 0

    // Идентификация
    requestId = null
    requestSent = false
    requestResolved = false
    requestLoading = false
    requestResendAt = null
    identificationUrls = []
    /**
     * Временный телефон для идентификации, если нет вообще никаких телефонов у клиента
     * @type {null|string}
     */
    tempPhone = null

    online = true

    /**
     * Поле ввода в чатах
     * @type {string}
     */
    messageInput = ""

    // В data хранятся только фиксированные данные, которые не будут меняться
    data = {
        userName: "",
        dialogID: "",
        source: "",
        sourceId: null,
        modileId: undefined,
        /**
         * Входящий телефон incomingPhone
         * @type {string}
         */
        phone: "",
        lang: "uz",
        avatar: null,
    }

    constructor(roomData) {
        super({});
        const incomingClientSources = app.$store.state.incomingClientSources;
        this.data = roomData;
        this.lang = roomData.lang;
        this.chatRoom = roomData.chatRoom;
        this.customerForm = new CustomerData(null, roomData.phone);

        let source = incomingClientSources.find(source => source.slug === roomData.source);
        if(!source) {
            source = incomingClientSources.find(source => source.slug === "Chats");
        }
        if(source) {
            this.data.sourceId = source.id;
        }

        if (roomData.dialogVars && typeof roomData.dialogVars[Symbol.iterator] === "function") {
            const mobileId = roomData.dialogVars.find(el => el.name.toLowerCase() === "mobileid");
            if (mobileId && parseInt(mobileId.value) > 0) {
                this.data.modileId = parseInt(mobileId.value);
            }
        }
    }
}

